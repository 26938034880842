// @flow

import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { addLocale, useLocale } from "ttag";
import queryString from "query-string";

import { SearchPage } from "@containers";
import {
    AgencyOverviewBreadcrumb,
    AgencySearchMap,
    AgencySearchResults,
    AgencyFilterWrapper,
} from "@components";
import { AgencyOverviewSearchFactory } from "@utils/search";
import { navigate } from "@reach/router";
import { mapAgenciesForSearch } from "@utils/c21-api/mappers/agency";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { EVENT_MAP, type PageImpressionParams } from "../models/events";
import { TrackPageImpression } from "../components/Tracker/Tracker";

const DEFAULT_SHOW_MAP = false;
const AgencyPageTemplate = ({
    data: {
        contentfulNavigation: { navigationItems },
        footerItems,
        c21Translation: { translations },
        allC21Agency: { nodes: _agencies },
    },
    pageContext: { locale, slug, translationSlugs },
}: *) => {
    addLocale(locale, translations);
    useLocale(locale);
    const gtmDispatcher = useGTMDispatch();

    const [search, setSearch] = useState();
    const [agencies, _setAgencies] = useState(_agencies);
    const [page, setPage] = useState(1);
    const [qs, setQs] = useState();
    const [currentFilter, setCurrentFilter] = useState();

    const setAgencies = (newAgencies: ?Array<*>) => {
        _setAgencies(
            newAgencies && newAgencies.length ? newAgencies : _agencies,
        );
        setPage(1);
    };

    useEffect(() => {
        const params: PageImpressionParams = {
            agency: null,
            agencypage: "false",
            language: locale,
            pagename: "agency overview",
            typetemplate: "agency-overview",
        };
        TrackPageImpression(gtmDispatcher, EVENT_MAP.PageImpression, params);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [page]);

    useEffect(() => {
        if (
            (currentFilter &&
                currentFilter.latitude &&
                currentFilter.longitude) ||
            (currentFilter && currentFilter.type)
        ) {
            setSearch(
                AgencyOverviewSearchFactory.fromCoordinates(currentFilter),
            );
        } else {
            setSearch(undefined);
        }
    }, [currentFilter]);

    useEffect(() => {
        const fetch = async () => {
            const result = await search?.search();

            if (!result) return;

            const mapped = mapAgenciesForSearch(result.data.data, locale);

            setAgencies(mapped);
            setQs(search?.toBrowserQueryString());
        };

        if (!search) {
            setAgencies(_agencies);
            setQs(undefined);
        } else {
            fetch();
        }
    }, [search]);

    useEffect(() => {
        const parsedQs = queryString.parse(window.location.search) || {};
        if (Object.keys(parsedQs).length) {
            if (parsedQs.type) {
                parsedQs.type = parsedQs.type.split("-");
            }
            setCurrentFilter(() => parsedQs);
        }
    }, []);

    const optionsWithQuery = {};

    Object.keys(translationSlugs).forEach(
        (key) =>
            (optionsWithQuery[key] = `${translationSlugs[key]}${
                search?.toBrowserQueryString() || ""
            }`),
    );

    useEffect(() => {
        navigate(`${slug}${qs || ""}`, {
            replace: true,
        });
    }, [qs]);

    const filter = (
        <AgencyFilterWrapper
            currentFilter={currentFilter}
            onFilterChanged={setCurrentFilter}
            language={locale}
            filterType={"agencyFilter"}
        />
    );

    const map = (
        <AgencySearchMap
            agencies={agencies}
            currentPage={page}
            isSortedByLocation={!!currentFilter}
        />
    );

    const results = (
        <AgencySearchResults
            agencies={agencies}
            currentPage={page}
            onPageChanged={setPage}
        />
    );
    return (
        <SearchPage
            headerProps={{
                locale,
                navigationItems,
                translationSlugs: optionsWithQuery,
                footerItems,
            }}
            Filter={filter}
            Map={map}
            Results={results}
            showMapByDefault={DEFAULT_SHOW_MAP}
            Breadcrumb={<AgencyOverviewBreadcrumb locale={locale} />}
        />
    );
};

export default AgencyPageTemplate;

export const query = graphql`
    query ($locale: String!, $country: String!) {
        contentfulNavigation(
            title: { eq: "Main Navigation" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        footerItems: contentfulNavigation(
            title: { eq: "Footer" }
            node_locale: { eq: $locale }
            country: { eq: $country }
        ) {
            ...ContentfulNavigation
        }
        c21Translation(locale: { eq: $locale }) {
            translations(
                keys: [
                    "common.filters.*"
                    "slug.agency"
                    "map.*"
                    "breadcrumbs.agency*"
                    "agency.header.button.text"
                    "slug.estimation-tool"
                    "agency.card.button.*"
                    "form.hq-contact.subject.open-office"
                    "slug.open-agency"
                    "agency-overview.our-agencies"
                ]
            )
        }
        allC21Agency(
            filter: { locale: { eq: $locale } }
            sort: { fields: name, order: ASC }
        ) {
            nodes {
                api_id
                name
                slug
                location {
                    longitude
                    latitude
                }
                address {
                    street
                    postalCode
                    number
                    country
                    city
                    box
                }
                email
                phoneNumber
                facebookUrl
                images {
                    description
                    fixed(width: 235, height: 235) {
                        width
                        height
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                    }
                }
            }
        }
    }
`;
